export default [
  {
    path:'DevLayout',
    component: () => import('@/views/personalCenter/DevLayout.vue'),
    redirect:'/personalCenter/identityAuthentication',
    children:[
      
      {  // 修改密码
        path:'/personalCenter/Password',
        component: () => import('@/views/personalCenter/Password.vue')
      },
      {  // 修改手机号
        path:'/personalCenter/cellphoneNumber',
        component: () => import('@/views/personalCenter/cellphoneNumber.vue')
      },
      {  // 地址管理
        path:'/personalCenter/addressmanage',
        component: () => import('@/views/personalCenter/addressmanage.vue')
      },
      {  // 发票抬头管理
        path:'/personalCenter/inVBRK',
        component: () => import('@/views/personalCenter/inVBRK.vue')
      },
      {  // 我的订单
        path:'/personalCenter/MyOrder',
        component: () => import('@/views/personalCenter/MyOrder.vue')
      },
      {  // 身份认证
        path:'/personalCenter/identityAuthentication',
        component: () => import('@/views/personalCenter/identityAuthentication.vue')
      },
      {  // 我的优惠券
        path:'/personalCenter/myCoupon',
        component: () => import('@/views/personalCenter/myCoupon.vue')
      },
      {  // 学习统计
        path:'/personalCenter/LearnStatistics',
        component: () => import('@/views/personalCenter/LearnStatistics.vue')
      },
    ]
  },
]
